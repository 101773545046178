import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, MenuList, Paper, Popper, Badge, Button, Box, CircularProgress } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import Adjust from '@mui/icons-material/Adjust';
import moment from 'moment';
import { ReactComponent as MsgSeen } from './icons/MsgSeenIcon.svg';
import { ReactComponent as NotificationIcon } from './icons/bell_icon.svg';
import styles from './styles.module.scss';
import { getNotifications, markAllAsRead, updateNotification } from '../../../../../new-store/top-bar/actions';
import { topBarSelector } from '../../../../../new-store/top-bar/selectors';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const user = useSelector(({ authentication }) => authentication.profile);
  const anchorRef = useRef(null);
  const { notifications: notificationState } = useSelector(topBarSelector);

  useEffect(() => {
    dispatch(getNotifications(user.id));
  }, [user]);

  const handleClick = event => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const getDaysInNaturalLanguage = days => {
    if (days === 0) {
      return 'Today';
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days === 7) {
      return '1 week ago';
    } else if (days === 14) {
      return '2 week ago';
    } else if (days === 30) {
      return '1 month ago';
    } else {
      return `${days} days ago`;
    }
  };

  const calculateDateDifference = date => {
    const notificationDate = moment(date).startOf('day');
    const currentDate = moment();
    const diffDays = currentDate.diff(notificationDate, 'days');
    return getDaysInNaturalLanguage(diffDays);
  };

  const handleMarkAllAsRead = () => {
    dispatch(markAllAsRead(user.id));
  }

  const MenuItemComponent = ({ item }) => {
    const [expanded, setExpanded] = useState(false);
    const [isRead, setIsRead] = useState(item.isRead);

    const handleReadNotification = () => {
      setIsRead(!isRead);
      dispatch(updateNotification({ id: item.id, data: { ...item, isRead: !isRead }}));
    };

    const SelectMsgSeenIcon = ({ isRead }) => {
      return (
        <Box onClick={handleReadNotification} sx={{ cursor: 'pointer' }}>
          {
            isRead ? (
              <MsgSeen style={{ width: 14, marginRight: 5, marginLeft: 5, color: '#EB1700' }} />
            ) : (
              <Adjust style={{ fontSize: 14, marginRight: 5, marginLeft: 5 }} />
            )
          }
        </Box>
      )
    };

    return (
      <MenuItem
        className={styles.menuItem}
        sx={{ cursor: 'default', '&:hover':  {backgroundColor: 'inherit' } }}
        disableRipple
      >
        <div className={styles.menuItemDiv}>
          <div className={styles.topDiv}>
            <div className={styles.campaignName}>{item.title}</div>
            <div className={styles.iconDiv}>
              <SelectMsgSeenIcon isRead={isRead} />
            </div>
          </div>
          <Box sx={{ py: .5 }}>
            <Box sx={{ py: .25 }}>
              <Box className={styles.body} sx={{...(expanded ? {} : {maxHeight: '2.4em', '-webkit-line-clamp': '2' })}}>
                {item.body}
              </Box>
            </Box>
            <div>
              <Button sx={{ p: 0, fontSize: '12px', fontWeight: 'normal' }} variant='text' size='small' onClick={() => setExpanded(!expanded)}>{ expanded ? t('top_bar.show_less') : t('top_bar.show_more') }</Button>
            </div>
          </Box>
          <span style={{ fontSize: '14px', opacity: '0.6' }}>
            {calculateDateDifference(item.createdAt)}
          </span>
        </div>
      </MenuItem>
    );
  };

  const GetMenuItems = () => {
    return (
      <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown} className={styles.menuList}>
        {
          notificationState.data.length > 0 ? (
            notificationState.data.map(item => <MenuItemComponent item={item} key={item.id} />)
          ) : (
            <MenuItem className={styles.menuItem}>
              <div className={styles.menuItemDiv}>
                <div className={styles.topDiv}>
                  <div className={styles.campaignName}></div>
                  <div className={styles.iconDiv}></div>
                </div>
                <div className={styles.bottomDiv}>{'no notification'}</div>
              </div>
            </MenuItem>
          )
        }
      </MenuList>
    );
  };

  return (
    <>
      <Badge
        ref={anchorRef}
        badgeContent={notificationState.data.filter(n => !n.isRead).length}
        color="primary"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <NotificationIcon
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          className={styles.notificationIcon}
        />
      </Badge>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom"
        disablePortal
        className={styles.popper}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper className={styles.paper}>
            <GetMenuItems />
            <Button
              onClick={handleMarkAllAsRead}
              disabled={notificationState.markAllAsReadPending}
              endIcon={notificationState.markAllAsReadPending ? <CircularProgress color='inherit' size={16} /> : null}
            >
              {t('top_bar.mark_read')}
            </Button>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default Notification;
