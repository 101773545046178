export const campaignType = '/api/v2/campaign-types/all';
export const campaign = '/api/v2/campaigns';
export const adminNodeList = '/api/v2/campaigns/admin-nodes-list';
export const subject = '/api/v2/subjects';
export const users = '/api/v2/users';
export const campaignListAll = '/api/v2/campaigns';
export const s3UploadLink = '/api/v2/attachments/s3/upload';
export const s3FileDownload = '/api/v2/attachments/s3/download?filename=';
export const s3DeleteLink = '/api/v2/attachments/s3/delete?fileName=';
export const saveCustomerJourneyData = '/api/v2/nodes/save-nodes';
export const profileUrl = '/api/v2/campaigns';
export const productListUrl = '/api/v2/subjects';
export const authApi = '/api/v2/auth';
export const getFragmentCount = '/api/v2/fragments';
export const userNotificationApi = '/api/v2/notification';
export const productTags = '/api/v2/tags/tags-by-product';
export const ctaTemplates = '/api/v2/ctas/cta-templates/therapeutic';
export const kpisUrl = '/api/v2/kpis';
export const campaignStepsCompletionUrl = '/api/v2/steps/campaign-step-completion';
export const messages = '/api/v2/teams-messages';
export const switchBasePath = '/api/v2/switch';
export const channels = '/api/v2/teams-channels';
export const nodes = '/api/v2/nodes';
export const steps = '/api/v2/steps';
// integration + internal team
export const team = '/api/v2/team';
export const websiteTypes = '/api/v2/website-types';
export const projectTypes = '/api/v2/project-types';
export const fragments = '/api/v2/fragments';
export const urls = '/api/v2/urls';
export const emailTemplates = '/api/v2/steps/email-templates';
export const massMailTemplates = '/api/v2/steps/mass-mail-templates';
export const allMailTemplates = '/api/v2/steps/email-and-mass-mail-templates';
export const adminExportReport = '/api/v2/campaigns/get-admin-export-report';
export const emailer = '/api/v2/emailer';
export const profile = '/api/v2/profile';
export const tasks = '/api/v2/tasks';
export const country = '/api/v2/country';

// applies to calls that have a one-to-many relationship
// query param: filter=country.id||$in||:ids
export const COUNTRY_FILTER_CALLS = [team];
// applies to calls that have a many-to-many relationship
// query param: filter=countries.id||$in||:ids
export const COUNTRIES_FILTER_CALLS = [websiteTypes];

// applies to calls that have a custom service function
// query param: countryIds=:ids
export const CUSTOM_COUNTRY_FILTER_CALLS = [
  ctaTemplates,
  emailTemplates,
  massMailTemplates,
  allMailTemplates,
];
