import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, updateCountries } from "../../../new-store/top-bar/actions";
import { topBarSelector } from "../../../new-store/top-bar/selectors";
import { getUniqueUserCountries } from "../../../helpers/user.helpers";
import { enqueueNotification } from "../../../store/notification/action";
import { useTranslation } from "react-i18next";

const ChangeUserCountry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // @ts-ignore
  const profile = useSelector(({ authentication }) => authentication.profile);
  const topBarState = useSelector(topBarSelector);

  const [userCountries, setUserCountries] = useState<number[]>([]);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch])

  useEffect(() => {
    if (profile) {
      setUserCountries(getUniqueUserCountries(profile).map(c => Number(c.id)));
    }
  }, [profile]);

  const handleChange = (e: SelectChangeEvent<number[]>) => {
    const value = e.target.value;

    const updatedValue = typeof value === 'string' ? value.split(',').map(v => Number(v)) : value;

    if (updatedValue.length === 0) {
      dispatch(enqueueNotification(t('top_bar.no_countries_warning')));

      return;
    }

    setUserCountries(updatedValue);
    setHasChanged(true);
  }

  const handleClose = async () => {
    if (hasChanged) {
      await dispatch(updateCountries({ countries: userCountries }));
  
      // @ts-ignore
      window.location = window.location.origin;
    }
  }

  if (!profile.canSwitchCountry || !topBarState.countries.fullfilled) return null;

  return (
    <FormControl fullWidth>
      <Select
        sx={{ minWidth: 120, height: 40, mr: 2 }}
        multiple
        value={userCountries}
        renderValue={(selected: number[]) => topBarState.countries.data.filter(c => selected.includes(c.id)).map(c => c.name).join(', ')}
        onChange={handleChange}
        onClose={handleClose}
        MenuProps={{
          sx: {
            zIndex: 1320
          },
        }}
      >
        {
          topBarState.countries.data.map(c => (
            <MenuItem id={c.id} key={c.id} value={c.id}>
              <Checkbox checked={userCountries.includes(c.id)} />
              <ListItemText primary={c.name} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default ChangeUserCountry;
