import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './FileUpload.scss';
import fileupload from './icons/file-upload.png';
import { enqueueNotification } from '../../../../store/notification/action';
import { useDispatch } from 'react-redux';

const FileUpload = ({ upload, redBroder = false, isSms = false, isMultiple = true, styles = {}, disabled = false }) => {
  const fileInputRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const smsFileTypes = ['xlsx', 'xls', 'docx', 'pptx', 'zip', 'rar', '7z'];
  const smsMaxAllowedSize = 50 * 1024 * 1024;

  const preventDefault = e => {
    e.preventDefault();
  };

  const dragOver = e => {
    preventDefault(e);
  };

  const dragEnter = e => {
    preventDefault(e);
  };

  const dragLeave = e => {
    preventDefault(e);
  };

  const checkForSizeLimit = files => {
    if (files[0]?.size > smsMaxAllowedSize) {
      dispatch(enqueueNotification('upload file size less than 50Mb', 'error'));
    } else {
      upload(files);
    }
  };

  const fileDrop = e => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (isSms) {
      const fileName = files[0].name;
      const filetype = fileName.split('.')[1];
      const shouldAcceptFile = smsFileTypes.includes(filetype);
      if (shouldAcceptFile) {
        checkForSizeLimit(files);
      } else {
        dispatch(enqueueNotification('file format not supported', 'error'));
      }
    } else {
      const toUpload = isMultiple ? files : [files[0]];
      upload(toUpload);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      const files = fileInputRef.current.files;
      if (isSms) {
        const fileName = files[0].name;
        const filetype = fileName.split('.')[1];
        const shouldAcceptFile = smsFileTypes.includes(filetype);
        if (shouldAcceptFile) {
          checkForSizeLimit(files);
        } else {
          dispatch(enqueueNotification('file format not supported', 'error'));
        }
      } else {
        upload(files);
      }
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="container">
      <div
        className="drop-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        style={
          redBroder ? { ...styles?.dropContainer, border: '2px dashed red' } : styles?.dropContainer
        }
      >
        <div className="drop-message" style={ disabled ? { ...styles?.dropMessage, cursor: 'default' } : { ...styles?.dropMessage }}>
          <img
            src={fileupload}
            alt="dummy"
            width={styles?.img?.width ?? 100}
            height={styles?.img?.height ?? 100}
          />
          <div className="drop-message-text">
            <span className="text-center">
              {t('journey.files_tab.UPLOAD_YOUR_DOCUMENT')}
              <br />
            </span>
            <p style={ disabled ? { color: 'rgba(0, 0, 0, 0.38)', textDecoration: 'none' } : {} }>{t('journey.files_tab.DRAG_AND_DROP')}</p>
          </div>
        </div>
        {isSms ? (
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            accept=".xlsx,.xls,.docx,.pptx,.zip,.rar,.7z"
            multiple={isMultiple}
            onChange={filesSelected}
            disabled={disabled}
          />
        ) : (
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple={isMultiple}
            onChange={filesSelected}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default FileUpload;
