import axios from '../../api';
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProfiles = createAsyncThunk('profileList', async () => {
  const response = await axios.get('profile/get-all-profiles');

  return response.data;
});

export const getCountries = createAsyncThunk('countryList', async () => {
  const response = await axios.get('country');

  return response.data;
});

export const updateCountries = createAsyncThunk('updateCountries', async (payload: { countries: number[] }) => {
  await axios.patch('users/update-user-countries', payload);
});

export const getNotifications = createAsyncThunk('notificationList', async (userId) => {
  const response = await axios.get(`notification/user-notifications/${userId}`);
  return response.data;
});

export const updateNotification = createAsyncThunk('updateNotification', async (payload: { id: number, data: any }) => {
  const response = await axios.patch(`notification/${payload.id}`, payload.data);
  return response.data;
});

export const markAllAsRead = createAsyncThunk('markAllAsRead', async (userId) => {
  const response = await axios.patch(`notification/user-notifications/${userId}/read`);
  return response.data;
});
