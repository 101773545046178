import axios from 'axios';
import { AuthService } from '../app/auth/AuthService';
import { getStore } from '../store/store-accessor';

// applies to calls that have a one-to-many relationship
// query param: filter=country.id||$in||:ids
const COUNTRY_FILTER_CALLS = ['/team'];

// applies to calls that have a many-to-many relationship
// query param: filter=countries.id||$in||:ids
const COUNTRIES_FILTER_CALLS = ['/website-types'];

// applies to calls that have a custom service function
// query param: countryIds=:ids
const CUSTOM_COUNTRY_FILTER_CALLS = ['/ctas/cta-templates/therapeutic', '/email-templates', '/mass-mail-templates', '/email-and-mass-mail-templates'];

const instance = axios.create({
  baseURL: '/api/v2',
  validateStatus: status => status >= 200 && status < 400,
  headers: {
    Accept: 'application/json',
  },
});

const auth = {
  accessToken: () => {
    try {
      const persisted = JSON.parse(localStorage.getItem('persist:orchestra') || '');
      return JSON.parse(persisted.authentication).accessToken;
    } catch (error) {
      return null;
    }
  },
};

instance.interceptors.request.use(
  async config => {
    const accessToken = auth.accessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${auth.accessToken()}`;
    }

    // country filtering
    let params = {};
    if (
      config.url &&
      new RegExp(`${[...COUNTRY_FILTER_CALLS, ...COUNTRIES_FILTER_CALLS, ...CUSTOM_COUNTRY_FILTER_CALLS].join('|')}`).test(config.url)
    ) {
      const campaignCountryIds: string = getStore()
        ?.getState()
        ?.campaignProfile?.campaignProfile?.campaignDetails?.countries
        ?.map((country: any) => country.id)
        .join(',');

      if (campaignCountryIds?.length) {
        switch (true) {
          case COUNTRY_FILTER_CALLS.length && new RegExp(`${COUNTRY_FILTER_CALLS.join('|')}`).test(config.url):
            params = { filter: `country.id||$in||${campaignCountryIds}` };
            break;
          case COUNTRIES_FILTER_CALLS.length && new RegExp(`${COUNTRIES_FILTER_CALLS.join('|')}`).test(config.url):
            params = { filter: `countries.id||$in||${campaignCountryIds}` };
            break;
          case CUSTOM_COUNTRY_FILTER_CALLS.length && new RegExp(`${CUSTOM_COUNTRY_FILTER_CALLS.join('|')}`).test(config.url):
            params = { countryIds: campaignCountryIds };
            break;
        }
      }
    }

    config.params = {
      ...config.params,
      ...params
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const service = new AuthService();
      const refresh = await service.refreshToken();

      if (refresh) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${refresh.accessToken}`;
        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
