import axios from 'axios';
import { steps } from '../../app/constants/api-constants';
import {
  GET_STATUS_OPTIONS,
  GET_STATUS_OPTIONS_FAILED,
} from './types';

export const getStatusOptions = () => async (dispatch) => {
  try {
    const res = await axios.get(`${steps}/statuses`);
    dispatch({
      type: GET_STATUS_OPTIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_STATUS_OPTIONS_FAILED,
    });
  }
};

