import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import TextFieldInput from '../../../../../pages/briefs/components/TextFieldInput';
import axios from 'axios';
import { emailer } from '../../../../constants/api-constants';
import { SupportOptions } from './ContactSupport';
import FileUpload from '../../../forms-components/file-upload/FileUpload';
import { enqueueNotification } from '../../../../../store/notification/action';
import ValidationError from '../../../../../pages/briefRequests/components/ValidationError';
import DeleteIcon from '@mui/icons-material/Delete';

interface BugFormProps {
    topic: SupportOptions
    handleClose: () => void
}

const ContactSupportForm = ({ topic, handleClose }: BugFormProps) => {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            summary: '',
            message: '',
            files: []
        },
        onSubmit: async (values: any) => {
            setSubmitting(true);

            const data = new FormData();
            data.append('topic', topic);
            data.append('summary', values.summary);
            data.append('message', values.message);

            values.files.forEach((file: File) => {
                // @ts-ignore
                data.append('files', file);
            });

            try {
                await axios.post(`${emailer}/contact-support`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                dispatch(enqueueNotification('Thank you for your message. The project team has been informed and will come back to you shortly.', 'info', 5000));
                setSubmitting(false);
                handleClose();
            } catch (error) {
                dispatch(enqueueNotification('Something went wrong when submitting your message, please try again.', 'error', 5000));
                setSubmitting(false);
                handleClose();
            }
        },
        validate: (values: any) => {
            const errors: {
                summary?: string
                message?: string
            } = {};

            if (!values.summary) {
                errors.summary = 'Please fill out this field.';
            }

            if (!values.message) {
                errors.message = 'Please fill out this field.';
            }

            return errors;
        }
    });

    const handleFileDelete = (fileToDelete: File) => {
        formik.setFieldValue('files', formik.values.files.filter((file: File) => file.name !== fileToDelete.name));
    }

    return (
        <Box>
            <Divider sx={{ mt: 4, mb: 2 }} />

            <form id='bug-form' onSubmit={formik.handleSubmit}>
                <Box sx={{ mb: 2 }}>
                    <TextFieldInput
                        label='Summary'
                        value={formik.values.summary}
                        onChange={(value: string) => formik.setFieldValue('summary', value)}
                        hasRequiredIndicator
                        maxChars={100}
                        disableMaxCharsIndicator
                    />
                    {
                        formik.errors.summary && formik.touched.summary &&
                        <ValidationError message={formik.errors.summary as string} />
                    }
                </Box>

                <Box>
                    <TextFieldInput
                        label='Message'
                        value={formik.values.message}
                        onChange={(value: string) => formik.setFieldValue('message', value)}
                        hasRequiredIndicator
                        multiline
                        rows={4}
                    />
                    {
                        formik.errors.message && formik.touched.message &&
                        <ValidationError message={formik.errors.message as string} />
                    }
                </Box>

                <Box sx={{ mt: 2}}>
                    <FileUpload
                        upload={(files: any) => formik.setFieldValue('files', Array.from(files))}
                        styles={{
                            dropContainer: {
                                height: '200px',
                                width: '100%'
                            }
                        }}
                    />

                    {
                        formik.values.files.map((file: File) => {
                            return (
                                <Box key={file.name} className="file-details-div">
                                    <p className="file-title">{file.name}</p>
                                    <DeleteIcon onClick={() => handleFileDelete(file)} className="delete-button" />
                                </Box>
                            )
                        })
                    }
                </Box>

                <Box className='contact-support-actions' sx={{ mt: 2 }}>
                    <Button type='submit' form='bug-form' variant='contained' sx={{ mr: 1 }} disabled={submitting}>Send</Button>
                    <Button onClick={handleClose} disabled={submitting}>Cancel</Button>
                </Box>
            </form>
        </Box>
    )
}

export default ContactSupportForm;
