export const LOAD_CAMPAIGN_KPIS = 'load_campaign_kpi';
export const LOAD_CAMPAIGN_KPIS_FAILURE = 'load_campaign_kpi_failure';
export const LOAD_CAMPAIGN_KPIS_SUCCESS = 'load_campaign_kpi_success';
export const REFRESH_KPIS = 'refresh_kpis';
export const REFRESH_KPIS_TASK_CREATED = 'refresh_kpis_task_created';
export const REFRESH_KPIS_TASK_UPDATED = 'refresh_kpis_task_updated';
export const REFRESH_KPIS_SUCCESS = 'refresh_kpis_success';
export const REFRESH_KPIS_FAILURE = 'refresh_kpis_failure';
export const GET_LAST_SYNC_DATE = 'get_last_sync_date';
export const GET_LAST_SYNC_DATE_SUCCESS = 'get_last_sync_date_success';
export const GET_LAST_SYNC_DATE_FAILURE = 'get_last_sync_date_failure';
export const GET_CAMPAIGN_PERF_KPI = 'get_campaign_perf_kpi';
export const GET_CAMPAIGN_PERF_KPI_SUCCESS = 'get_campaign_perf_kpi_success';
export const GET_CAMPAIGN_PERF_KPI_FAILURE = 'get_campaign_perf_kpi_failure';
export const GET_CHILD_EVENTS = 'get_child_events';
export const GET_CHILD_EVENTS_SUCCESS = 'get_child_events_success';
export const GET_CHILD_EVENTS_FAILURE = 'get_child_events_failure';
