import { createReducer } from "@reduxjs/toolkit";
import { initialState, TopBarState } from "./types";
import { getCountries, getNotifications, getProfiles, markAllAsRead, updateNotification } from "./actions";

export default createReducer(initialState as TopBarState, builder => {
  builder
    .addCase(getProfiles.pending, state => {
      state.profiles.pending = true;
    })
    .addCase(getProfiles.fulfilled, (state, { payload }) => {
      state.profiles.pending = false;
      state.profiles.fullfilled = true;
      state.profiles.data = payload;
    })
    .addCase(getProfiles.rejected, state => {
      state.profiles.pending = false;
      state.profiles.error = true;
    })

    .addCase(getCountries.pending, state => {
      state.countries.pending = true;
    })
    .addCase(getCountries.fulfilled, (state, { payload }) => {
      state.countries.pending = false;
      state.countries.fullfilled = true;
      state.countries.data = payload;
    })
    .addCase(getCountries.rejected, state => {
      state.countries.pending = false;
      state.countries.error = true;
    })

    .addCase(getNotifications.pending, state => {
      state.notifications.pending = true;
    })
    .addCase(getNotifications.fulfilled, (state, { payload }) => {
      state.notifications.pending = false;
      state.notifications.fullfilled = true;
      state.notifications.data = payload;
    })
    .addCase(getNotifications.rejected, state => {
      state.notifications.pending = false;
      state.notifications.error = true;
    })

    .addCase(updateNotification.pending, state => {})
    .addCase(updateNotification.fulfilled, (state, { payload }) => {
      state.notifications.data = [
        ...(state.notifications.data.filter(n => n.id !== payload.id)),
        payload
      ]
    })
    .addCase(updateNotification.rejected, state => {})

    .addCase(markAllAsRead.pending, state => {
      state.notifications.markAllAsReadPending = true;
    })
    .addCase(markAllAsRead.fulfilled, (state, { payload }) => {
      state.notifications.markAllAsReadPending = false;
      state.notifications.fullfilled = true;
      state.notifications.data = payload;
    })
    .addCase(markAllAsRead.rejected, state => {
      state.notifications.markAllAsReadPending = false;
      state.notifications.error = true;
    })
});
