//@ts-nocheck
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useCheckForOwnership, userTypes } from '../../auth/userPermissions';
import { featureSelector } from '../../../new-store/features/selectors';

interface IProps {
    code: string
    checkOwnership?: boolean
    children: ReactElement
}

enum AccessType {
    NoAccess = 'NA',
    ReadOnly = 'RO',
    ReadWrite = 'RW'
}

const MEDICAL_PROFILES = ['MSL', 'Medical Manager'];

export const checkIfUserHasMedicalProfile = (user) => {
    if (!user || !user.profile) return false;

    return MEDICAL_PROFILES.includes(user.profile.name);
}

const voteOnFeature = (feature, rwCallback, roCallback, naCallback, defaultCallback) => {  
    switch (feature?.accessType) {
        case AccessType.ReadWrite:
            return rwCallback();
        case AccessType.ReadOnly:
            return roCallback();
        case AccessType.NoAccess:
            return naCallback();
        default:
            return defaultCallback();
    }
}

export const checkReadOnly = (featureState, code) => {
    const profileFeatures = featureState.profile.data;
    const countryFeatures = featureState.country.data;

    const profileFeature = profileFeatures?.find(pf => pf.feature.code === code);
    const countryFeature = countryFeatures?.find(cf => cf.feature.code === code);

    return countryFeature?.accessType === AccessType.ReadOnly
        ? true
        : profileFeature?.accessType === AccessType.ReadOnly;
}

export const checkAccessControl = (featureState, code) => {
    const profileFeatures = featureState.profile.data;
    const countryFeatures = featureState.country.data;

    const profileFeature = profileFeatures?.find(pf => pf.feature.code === code);
    const countryFeature = countryFeatures?.find(cf => cf.feature.code === code);

    return voteOnFeature(
        countryFeature,
        () => voteOnFeature(profileFeature, () => true, () => true, () => false, () => true),
        () => voteOnFeature(profileFeature, () => true, () => true, () => false, () => true),
        () => false,
        () => voteOnFeature(profileFeature, () => true, () => true, () => false, () => false)
    );
}

export const checkAccessForCountry = (featureState, country, code) => {
    const countryFeatures = featureState.country.data;
    const countryFeature = countryFeatures?.find(cf => cf.feature.code === code && cf.country.id === country.id);

    return voteOnFeature(countryFeature, () => true, () => true, () => false, () => false);
}

const AccessControl = ({ code, checkOwnership = false, children }: IProps) => {
    const ownership = useCheckForOwnership();
    const featureState = useSelector(featureSelector);
    const profileFeatures = featureState.profile.data;
    const countryFeatures = featureState.country.data;

    if (checkOwnership && ownership.user === userTypes.notOwner) {
        return null;
    }

    const profileFeature = profileFeatures?.find(pf => pf.feature.code === code);
    const countryFeature = countryFeatures?.find(cf => cf.feature.code === code);

    return voteOnFeature(
        countryFeature,
        () => voteOnFeature(profileFeature, () => children, () => React.cloneElement(children, { readOnly: true }), () => null, () => null),
        () => voteOnFeature(profileFeature, () => children, () => React.cloneElement(children, { readOnly: true }), () => null, () => null),
        () => false,
        () => voteOnFeature(profileFeature, () => children, () => React.cloneElement(children, { readOnly: true }), () => null, () => null),
    );
}

export default AccessControl;
