import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, FormControl, MenuItem } from '@mui/material';
import { updateProfile } from "../../auth/authActions";
import { getProfiles } from '../../../new-store/top-bar/actions';
import { topBarSelector } from '../../../new-store/top-bar/selectors';

const ChangeUserProfile = () => {

  const dispatch = useDispatch();
  const profile = useSelector(({ authentication }) => authentication.profile);
  const topBarState = useSelector(topBarSelector);
  
  const isAuthenticated = useSelector(
    state =>
      state.authentication.isAuthenticated &&
      state.authentication.accessToken &&
      state.authentication.profile,
  );

  const handleChange = async (profileId) => {
    await dispatch(updateProfile(profileId));
    window.location = window.location.origin;
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getProfiles());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div>
      { (profile.canSwitchProfile && topBarState.profiles.fullfilled) ? (
          <FormControl  fullWidth >
            <Select
              labelId="select-label"
              id="simple-select"
              sx={{ minWidth: 120, height: 40 }}
              className="select-role"
              value={profile.profile.name}
              onChange={(event, child) => handleChange(child.props.id)}
              MenuProps={{
                sx: {
                  zIndex: 1320
                },
              }}
            >
              {topBarState.profiles.data.map(p => (
                <MenuItem id={p.id} key={p.id} value={p.name}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

      ) : (
        <></>
      )}
    </div>
  );
};

export default ChangeUserProfile;
