import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authenticationReducer } from '../app/auth/authReducer';
import { authErrorReducer } from '../app/auth/authErrorReducer';
import { AdminListReducer } from './admin-list/reducer';
import { campaignCreateReducer } from './campaign-create/reducer';
import { campaignListingAllReducer } from './campaign-listing/reducer';
import { campaignProfileReducer } from './campaign-profile/reducer';
import { canvasBuilderReducer } from './customer-journey/reducer';
import { emailJourneyCreateReducer } from './email-journey/reducer';
import { notificationReducer } from './notification/reducer';
import { constantStateReducer } from './constant-state/reducer';
import { CalenderReducer } from './calender/reducer';
import TeamReducer from './team/reducer';
import GenericMailsReducer from './generic-mails/reducer';
import { campaignKpisReducer } from './campaign-kpis/reducers';
import { SwitchReducer } from './switch-editor/reducer';
import { projectTypesReducer } from './project/reducer';
import { campaignStepCompletionReducer } from './campaign-step-completion/reducers';

// new Redux Store items
import stepStatuses from '../new-store/step-statuses/reducer.ts';
import adminCampaignList from '../new-store/admin-nodes-list/reducer.ts';
import userCampaignList from '../new-store/user-campaign-list/reducer.ts';
import websiteReducer from '../new-store/websites/reducer.ts';
import websitePageReducer from '../new-store/website-pages/reducer.ts';
import websiteStatusReducer from '../new-store/website-status/reducer.ts';
import briefReducer from '../new-store/briefs/reducer.ts';
import briefRequestReducer from '../new-store/brief-requests/reducer.ts';
import therapeuticReducer from '../new-store/therapeutics/reducer.ts';
import productReducer from '../new-store/products/reducer.ts';
import tagReducer from '../new-store/tags/reducer.ts';
import templateReducer from '../new-store/templates/reducer.ts';
import briefCommentsReducer from '../new-store/brief-comments/reducer';
import agencyReducer from '../new-store/agencies/reducer';
import adminListViewReducer from '../new-store/admin-list-view/reducer';
import targetingReducer from '../new-store/targeting/reducer';
import lightViewReducer from '../new-store/light-view/reducer';
import featuresReducer from '../new-store/features/reducer';
import cloneCampaignReducer from '../new-store/clone-campaign/reducer';
import stepFormReducer from '../new-store/step-forms/reducer';
import topBarReducer from '../new-store/top-bar/reducer';
import { configureStore } from '@reduxjs/toolkit';
import { setStore } from './store-accessor';

const persistConfig = {
  key: 'orchestra',
  storage,
  whitelist: ['authentication'],
};

const reducers = combineReducers({
  adminCampaignList,
  customerJourneyBuilder: canvasBuilderReducer,
  campaignCreate: campaignCreateReducer,
  campaignListingAll: campaignListingAllReducer,
  emailJourneyCreate: emailJourneyCreateReducer,
  notification: notificationReducer,
  campaignProfile: campaignProfileReducer,
  adminList: AdminListReducer,
  authentication: authenticationReducer,
  authError: authErrorReducer,
  constantState: constantStateReducer,
  calenderView: CalenderReducer,
  team: TeamReducer,
  genericMails: GenericMailsReducer,
  campaignKpis: campaignKpisReducer,
  switch: SwitchReducer,
  projectTypes: projectTypesReducer,
  campaignStepCompletion: campaignStepCompletionReducer,
  stepStatuses,
  userCampaignList,
  websites: websiteReducer,
  websitePages: websitePageReducer,
  websiteStatus: websiteStatusReducer,
  briefs: briefReducer,
  briefRequests: briefRequestReducer,
  briefComments: briefCommentsReducer,
  therapeutics: therapeuticReducer,
  products: productReducer,
  tags: tagReducer,
  templates: templateReducer,
  agencies: agencyReducer,
  adminListView: adminListViewReducer,
  targeting: targetingReducer,
  lightView: lightViewReducer,
  features: featuresReducer,
  cloneCampaign: cloneCampaignReducer,
  stepForm: stepFormReducer,
  topBar: topBarReducer
});

const rootReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: rootReducer
});

setStore(store);

export default store;
