export const SAVE_CUSTOMER_JOURNEY = 'save_customer_journey';
export const SAVE_CUSTOMER_JOURNEY_FAILED = 'save_customer_journey_failed';
export const GET_CUSTOMER_JOURNEY = 'get_customer_journey';
export const GET_CUSTOMER_JOURNEY_FAILED = 'get_customer_journey_failed';
export const GET_EDGE_CTA_LIST = 'get_edge_cta_list';
export const UPDATE_EDGE_CTA_LIST = 'update_edge_cta_list';
export const CLEAR_CUSTOMER_JOURNEY = 'clear_customer_journey';
export const GET_CREATE_EVENT_SURVEY_STEP_AUTO = 'get_create_event_survey_step_auto';
export const SET_CREATE_EVENT_SURVEY_STEP_AUTO = 'set_create_event_survey_step_auto';
export const GET_CREATE_WEBSITE_STEP_AUTO = 'get_create_website_step_auto';
export const SET_CREATE_WEBSITE_STEP_AUTO = 'set_create_website_step_auto';
export const SET_DELETED_AUTOMATED_WEBSITE = 'set_deleted_automated_website';
export const RESET_DELETED_AUTOMATED_WEBSITES = 'reset_deleted_automated_websites';
export const GET_CALLS_DATA = 'get_calls_data';
export const UPDATE_CALLS_DATA = 'update_calls_data';
export const DELETE_CALLS_DATA = 'delete_calls_data';
export const GET_STEP_TARGETING = 'get_step_targeting';
