import axios from '../../api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const clearCloneCampaignState = createAction('clearCloneCampaignState');

export const getCampaign = createAsyncThunk('campaign', async (id: number) => {
    const response = await axios.get(`/campaigns/${id}`, {
        params: {
            join: ['campaignType', 'product', 'product.therapeutic', 'targetBookmark', 'tags', 'tags.tag']
        }
    });

    return response.data;
});

export const getProductList = createAsyncThunk('productList', async (payload: { campaignTypeId: number, countryId?: number }) => {
    const response = await axios.get(`/subjects/${payload.campaignTypeId}`, {
        params: {
            ...(payload.countryId ? { countryId: payload.countryId }:  {})
        }
    });

    return response.data;
});

export const getCampaignTypeList = createAsyncThunk('campaignTypeList', async () => {
    const response = await axios.get('/campaign-types/all');

    return response.data;
});

export const getTargetBookmarks = createAsyncThunk('targetBookmarks', async (payload: { therapeuticId: number, campaignTypeId: number, productId: number, countryId: number }) => {
    const response = await axios.get('/target-bookmarks', {
        params: {
            therapeutic: payload.therapeuticId,
            campaignType: payload.campaignTypeId,
            product: payload.productId,
            country: payload.countryId
        }
    });

    return response.data;
});

export const getTags = createAsyncThunk('tags', async (payload: { campaignTypeId: number, productId: number }) => {
    const response = await axios.get(`/tags/tags-by-product/${payload.productId}`, {
        params: {
            campaign_type_id: payload.campaignTypeId
        }
    });

    return response.data;
});

export const cloneCampaign = createAsyncThunk('cloneCampaign', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.post('/campaigns/clone', payload);

        return response.data;
    } catch (error) {
        // @ts-ignore
        return rejectWithValue(error.response.data);
    }
});