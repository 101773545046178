import { State } from "../default"

const commonState = {
  pending: true,
  error: false,
  fullfilled: false
}

export const initialState = {
  profiles: {
    data: [],
    ...commonState
  },
  countries: {
    data: [],
    ...commonState
  },
  notifications: {
    data: [],
    markAllAsReadPending: false,
    ...commonState
  }
}

interface DataArrayState extends State { data: any [] }
interface NotificationState extends DataArrayState { markAllAsReadPending: boolean }

export interface TopBarState {
  profiles: DataArrayState,
  countries: DataArrayState,
  notifications: NotificationState
}
